import React, { createContext, useReducer, useEffect, useContext } from "react";

import Cookies from "js-cookie";
import Swal from "sweetalert2";

import dal from '../dal/api-call';

const newAddress = []

const addressList = [
  {
    "Address1": "Troutbeck Walk",
    "Address2": "",
    "City": "CAMBERLEY",
    "CompanyName": "",
    "ComplainceId": "",
    "CountryCode": "",
    "County": "Surrey",
    "FormattedAddress": "",
    "Language": "",
    "PostalCode": "GU15 1BN",
    "StateCode": "",
    "Type": "Home"
  },
  {
    "Address1": "Troutbeck Walk 1",
    "Address2": "",
    "City": "CAMBERLEY",
    "CompanyName": "",
    "ComplainceId": "",
    "CountryCode": "",
    "County": "Surrey",
    "FormattedAddress": "",
    "Language": "",
    "PostalCode": "GU15 1BN",
    "StateCode": "",
    "Type": "Home"
  }
]

const tableColumnKeys = [
  "ReferenceNumber",
  "Decision",
  "source",
  "orderReferenceNumber",
  "transactionDate",
  "DecisionDescription",
  {"ShowEntireReport":
    [
      "RuleTriggered",
      "orderId",
      "title",
      "firstName",
      "middleName",
      "lastName",
      "dateOfBirth",
      "addressline1",
      "addressline2",
      "city",
      "country",
      "postalCode",
      "totalAmount",
      "productType",
      "channel",
      "fulfilmentType",
      "mid",
      "CaseReference",
      "authenticationIndex",
      "NewAuthenticateIndex",
      "ValidationScore",
      "VerificationScore",
      "AgeDOBMatch",
      "AgeRange",
      "NumPrimDataItems",
      "NumPrimDataSources",
      "StartDateOldestPrim",
      "NumPrimDataCategories",
      "CategoryData",
      "CategoryType",
      "CategoryText",
      "NumCatDataSources",
      "NumCatDataItems",
      "StartDateOldestCat",
      "ReturnedHRPCount",
    ],
  },
]

const tableColumnKeysKycdoc = [
  "tracking_number",
  "fileUrl",
  "created_date_time_iso",
  "external_transaction_id",
  "country",
  "screening_status",
]


const exportColumnslist = [    
  "ReferenceNumber",
  "Decision",
  "source",
  "orderReferenceNumber",
  "DecisionDescription",
  "RuleTriggered",
  "orderId",
  "transactionDate",
  "ProcessConfigName",
  "title",
  "firstName",
  "middleName",
  "lastName",
  "dateOfBirth",
  "addressline1",
  "addressline2",
  "city",
  "country",
  "postalCode",
  "totalAmount",
  "productType",
  "channel",
  "fulfilmentType",
  "mid",
  "CaseReference",
  "authenticationIndex",
  "NewAuthenticateIndex",
  "ValidationScore",
  "VerificationScore",
  "AgeDOBMatch",
  "AgeRange",
  "NumPrimDataItems",
  "NumPrimDataSources",
  "StartDateOldestPrim",
  "NumPrimDataCategories",
  "CategoryData",
  "CategoryType",
  "CategoryText",
  "NumCatDataSources",
  "NumCatDataItems",
  "StartDateOldestCat",
  "ReturnedHRPCount",
]

const initialState = {
    processConfig: "",
    outputFormat: "",
    title: "",
    foreName: "",
    middleName: "",
    surName: "",
    suffix: "",
    gender: "",
    dob: "",
    currFlatNum: "",
    currHouseName: "",
    currHouseNum: "",
    currPostCode: "",
    currStreet: "",
    currDistrict: "",
    currTown: "",
    currCountry: "GB",
    prevFlatNum: "",
    prevHouseName: "",
    prevHouseNum: "",
    prevPostCode: "",
    prevStreet: "",
    prevDistrict: "",
    prevTown: "",
    prevCountry: "",
    addresline1: "",
    addresline2: "",
    findAddressBuutonClick: false,
    fetchCurrentAddressFlag: false,
    tempAddress: "",
    isSubmitButtonDisable: true,
    allAddress: [],
    findPreviousAddressButtonClick: false,
    fetchPreviousAddressFlag: false,
    allPreviousAddress: [],
    response: null,
    isError: false,
    errorMessage: "",
    isRunQuery: false,
    isLoading: false,
    fromDate: undefined,
    toDate: undefined,
    filterparam: "",
    filterText: "All",
    reportData: [],
    filteredReportData: [],
    isFilteredReportData: false,
    totalRecordsCount: 0,
    offsetvalue: "",
    reportquery: "",
    reporttransactionDate: "",
    reporttransactionId: "",
    isSpin: false,
    isLoad: false,
    issubmit: false,
    flattenedData: [],
    isReportData: false,
    reportError: "",
    isErrorReport: false,
    tableColumnKeys: tableColumnKeys,
    exportColumnslist: exportColumnslist,
    entireDetails: undefined,
    entireDetailsFlag: undefined,
    reportDropdown: true,
    // isFetching: true
    testQueryCheck: false,
    testQuerySource: "SALT",
    testQuerySearchQuery: undefined,
    testQuerycountry: "GBR",
    searchOptions: [],
    searchOptionsObject: [],
    searchAddressKey: "",
    isFormatApi: false,
    isFormatFetch: false,
    formatAddress1: "",
    formatAddress2: "",
    formatTownCity: "",
    formatCounty: "",
    formatPostCode: "",
    formatCountry: "",

    kycdocData: [],
    filteredKycdocData: [],
    isFilteredKycdocData: false,
    isKycdocData: false,
    kycdocError: "",
    tableColumnKeysKycdoc: tableColumnKeysKycdoc,
  };

  let reducer = (state, action) => {
        switch(action.type) {
            case "PROCESS_CONFIG_CHANGE":
                return{
                    ...state,
                    processConfig: action.value,
                    isSubmitButtonDisable: false,
                };
            
            case "OUTPUT_FORMAT_CHANGE":
                return{
                    ...state,
                    outputFormat: action.value,
                    isSubmitButtonDisable: false,
                };
            
            case "GENDER_CHANGE":
                return{
                    ...state,
                    gender: action.value,
                    isSubmitButtonDisable: false,
                };
            case "TITLE_CHANGE":
              return{
                  ...state,
                  title: action.value,
                  isSubmitButtonDisable: false,
              };
            case "FORENAME_CHANGE":
              return{
                  ...state,
                  foreName: action.value,
                  isSubmitButtonDisable: false,
              };
            case "SURNAME_CHANGE":
              return{
                  ...state,
                  surName: action.value,
                  isSubmitButtonDisable: false,
              };
            case "MIDDLENAME_CHANGE":
              return{
                  ...state,
                  middleName: action.value,
                  isSubmitButtonDisable: false,
              };
            case "SUFFIX_CHANGE":
              return{
                  ...state,
                  suffix: action.value,
                  isSubmitButtonDisable: false,
              };
            case "DOB_CHANGE":
              // console.log("DOB_CHANGE : ", action.value.format("YYYY-MM-DDTHH:mm:ss"))
              return{
                  ...state,
                  dob: action.value!==null?(action.value.format("YYYY-MM-DDTHH:mm:ss")):(''),
                  isSubmitButtonDisable: false,
              };
            case "CURR_FLAT_NUM_CHANGE":
              return{
                  ...state,
                  currFlatNum: action.value,
                  isSubmitButtonDisable: false,
              };
            case "CURR_HOUSE_NAME_CHANGE":
              return{
                  ...state,
                  currHouseName: action.value,
                  isSubmitButtonDisable: false,
              };
            case "CURR_HOUSE_NUM_CHANGE":
              return{
                  ...state,
                  currHouseNum: action.value,
                  isSubmitButtonDisable: false,
              };
            case "CURR_POSTCODE_CHANGE":
              return{
                  ...state,
                  currPostCode: action.value,
                  isSubmitButtonDisable: false,
              };
            case "CURR_TOWN_CHANGE":
              return{
                  ...state,
                  currTown: action.value,
                  isSubmitButtonDisable: false,
              };
            case "CURR_STREET_CHANGE":
              return{
                  ...state,
                  currStreet: action.value,
                  isSubmitButtonDisable: false,
              };
            case "CURR_DISTRICT_CHANGE":
              return{
                  ...state,
                  currDistrict: action.value,
                  isSubmitButtonDisable: false,
              };
            case "CURR_COUNTRY_CHANGE":
              return{
                  ...state,
                  currCountry: action.value,
                  isSubmitButtonDisable: false,
              };

            case "PREV_FLAT_NUM_CHANGE":
              return{
                  ...state,
                  prevFlatNum: action.value,
                  isSubmitButtonDisable: false,
              };
            case "PREV_HOUSE_NAME_CHANGE":
              return{
                  ...state,
                  prevHouseName: action.value,
                  isSubmitButtonDisable: false,
              };
            case "PREV_HOUSE_NUM_CHANGE":
              return{
                  ...state,
                  prevHouseNum: action.value,
                  isSubmitButtonDisable: false,
              };
            case "PREV_POSTCODE_CHANGE":
              return{
                  ...state,
                  prevPostCode: action.value,
                  isSubmitButtonDisable: false,
              };
            case "PREV_TOWN_CHANGE":
              return{
                  ...state,
                  prevTown: action.value,
                  isSubmitButtonDisable: false,
              };
            case "PREV_STREET_CHANGE":
              return{
                  ...state,
                  prevStreet: action.value,
                  isSubmitButtonDisable: false,
              };
            case "PREV_DISTRICT_CHANGE":
              return{
                  ...state,
                  prevDistrict: action.value,
                  isSubmitButtonDisable: false,
              };
            case "PREV_COUNTRY_CHANGE":
              return{
                  ...state,
                  prevCountry: action.value,
                  isSubmitButtonDisable: false,
              };

            case "CLEAR_BUTTON_CLICK":
              console.log("CLEAR_BUTTON_CLICK")
              return{
                  ...state,
                  processConfig: "",
                  outputFormat: "",
                  title: "",
                  foreName: "",
                  middleName: "",
                  surName: "",
                  suffix: "",
                  gender: "",
                  dob: "",
                  currFlatNum: "",
                  currHouseName: "",
                  currHouseNum: "",
                  currPostCode: "",
                  currStreet: "",
                  currDistrict: "",
                  currTown: "",
                  // currCountry: "",
                  prevFlatNum: "",
                  prevHouseName: "",
                  prevHouseNum: "",
                  prevPostCode: "",
                  prevStreet: "",
                  prevDistrict: "",
                  prevTown: "",
                  prevCountry: "",
                  response: {},
                  isError: false,
                  isSubmitButtonDisable: false,
                  isRunQuery: false,
              };

            case "HANDLE_SUBMIT":

              // postData(action.value)
              // console.log("HANDLE_SUBMIT  : ", action.value)
              submitQuery(action.state, action.dispatch, action.value);

              return{
                ...state,
                isSubmitButtonDisable: true,
                isError: false,
                isLoading: true,
                
              };
            case "RESET_POPUP":
              // console.log("RESET_POPUP");

              return{
                ...state,
                isError: false,
                isSubmitButtonDisable: false,
                isRunQuery: false,
              };

            case "FIND_CURRENT_ADDRESS":
              // console.log("FIND_ADDRESS")
              
              // fetchAddress(action.queryState, action.dispatch);
              
              return{
                ...state,
                findAddressBuutonClick: true,
              };
            
            case "FETCH_CURRENT_ADDRESS":
              let fullAddress;

              fullAddress = addressList.map(
                myFunction
              )
              
              return{
                ...state,
                allAddress: [...new Set(fullAddress)],
                fetchCurrentAddressFlag: true,
              };
            
            case "RESET_CURRENT_ADDRESS":
              return{
                ...state,
                currFlatNum: "",
                currHouseName: "",
                currHouseNum: "",
                currPostCode: "",
                currStreet: "",
                currDistrict: "",
                currTown: "",
                currCountry: "",
                allAddress: [],
                findAddressBuutonClick: false,
                fetchCurrentAddressFlag: false,
              };

              case "FIND_PREVIOUS_ADDRESS":
                console.log("FIND_PREVIOUS_ADDRESS")
                
                // fetchAddress(action.queryState, action.dispatch);
                
                return{
                  ...state,
                  findAddressBuutonClick: true,
                };
              
              case "FETCH_PREVIOUS_ADDRESS":
                let fullPreviousAddress, newPreviousAddress;
  
                fullPreviousAddress = addressList.map(
                  myFunction
                )
                
                return{
                  ...state,
                  allPreviousAddress: [...new Set(fullAddress)],
                  fetchPreviousAddressFlag: true,
                };
              
              case "RESET_PREVIOUS_ADDRESS":
                return{
                  ...state,
                  prevFlatNum: "",
                  prevHouseName: "",
                  prevHouseNum: "",
                  prevPostCode: "",
                  prevStreet: "",
                  prevDistrict: "",
                  prevTown: "",
                  prevCountry: "",
                  allPreviousAddress: [],
                  findPreviousAddressButtonClick: false,
                  fetchPreviousAddressFlag: false,
                };
              
              case "FETCH_API_DATA":
                // console.log("FETCH_API_DATA :: ", action.payload)
                return{
                  ...state,
                  response: action.payload,
                  isRunQuery: true,
                  isLoading: false,
                };
              
              case "FETCH_ERROR":
                // console.log("FETCH_ERROR :: ",action.error)
                return {
                  ...state,
                  isError: action.status,
                  response: null,
                  isSubmitButtonDisable: false,
                  errorMessage: action.value,
                  isRunQuery: true,
                  isLoading: false,
                };
              case "UPDATE_TO_DATE":
                return{
                  ...state,
                  toDate: action.value
                }
              case "UPDATE_FROM_DATE":
                return{
                  ...state,
                  fromDate: action.value
                }
              case "UPDATE_FILTER_PARAM":
                return{
                  ...state,
                  filterparam: action.value==="None"?undefined:action.value,
                  filterText: action.filtervalue,
                }
              case "UPDATE_ENTIRE_REPORT_DETAIL_CHECKED":
                return{
                  ...state,
                  entireDetailsFlag : action.value
                }
              case "IS_LOADING":
                return{
                  ...state,
                  isLoad: action.load
                }
              case "IS_SUBMITTING":
                return{
                  ...state,
                  issubmit: action.issubmit
                }
              case "IS_SPIN":
                return{
                  ...state,
                  isSpin: action.spin
                }
              case "FETCH_REPORT_DATA_HANDLE": 
                fetchReportData(action.filterState, action.dispatch);
                return{
                  ...state,
                }

              case "FETCH_REPORT_DATA_NEW":
                
                let temp = state.reportData;
                temp.push(...action.data);
                return{
                  ...state,
                  reportData: [...new Set(temp)],
                  totalRecordsCount: temp.length,
                  isReportData: action.isdata,
                  isSpin: action.spin,
                  isErrorReport: false,
                  entireDetails: action.entiredetailflag
                }

              case "FETCH_KYCDOC_DATA":
                console.log('action');
                console.log(action);
                console.log(action.filterState);
                console.log(action.dispatch);
                fetchkycdoctData(action.filterState, action.dispatch);
                console.log(action);
                return{
                  ...state,
                }

              case "FETCH_KYCDOC_DATA_NEW":
                
                let tempkyc = state.kycdocData;
                tempkyc.push(...action.data);
                return{
                  ...state,
                  kycdocData: [...new Set(tempkyc)],
                  totalRecordsCount: tempkyc.length,
                  isKycdocData: action.isdata,
                  isSpin: action.spin,
                  isErrorKyc: false,
                  entireDetails: action.entiredetailflag
                }

                case "UPDATE_KYCDOC_OFFSET":
                console.log("UPDATE_KYCDOC_OFFSET :: ", action.offset);
                return{
                  ...state,
                }

              case "UPDATE_FILTERED_KYCDOC_DATA":
                return{
                  ...state,
                  filteredKycdocData: action.value,
                  isFilteredKycdocData: true,
                }
              case "KYCDOC_DATA_LENGTH":
                return{
                  ...state,
                  totalRecordsCount: state.flattenedData.length,
                }
              case "FLATTEN_KYCDOC_DATA":
                console.log('action');
                console.log(action);
                return{
                  ...state,
                  flattenedData: action.value?[...state.flattenedData, ...action.value]:[...state.flattenedData],
                  // totalRecordsCount: state.flattenedData.length,
                }

                case "SHOW_KYCDOC_DROPDOWN":
                  return{
                    ...state,
                    kycdocDropdown: action.value
                  }  
                case "CLEAR_KYCDOC_DATA":
                  return{
                    ...state,
                    kycdocData: [],
                    filteredKycdocData: [],
                    totalRecordsCount: 0,
                    offsetvalue: "",
                    kycdocquery: "",
                    kyctransactionDate: "",
                    kyctransactionId: "",
                    flattenedData: [],
                    isSpin: false,
                    isLoad: false,
                    issubmit: false,
                    isKycdocData: false,
                    kycdocError: "",
                    isErrorKycdoc: false,
                  }
                case "KYCDOC_ERROR":
                  return{
                    ...state,
                    kycdocError: action.value,
                    isErrorKycdoc: action.iserror,
                    isKycdocData: false,
                  }

              case "UPDATE_REPORT_OFFSET":
                console.log("UPDATE_REPORT_OFFSET :: ", action.offset);
                return{
                  ...state,
                }

              case "UPDATE_FILTERED_REPORT_DATA":
                return{
                  ...state,
                  filteredReportData: action.value,
                  isFilteredReportData: true,
                }
              case "REPORT_DATA_LENGTH":
                return{
                  ...state,
                  totalRecordsCount: state.flattenedData.length,
                }
              case "FLATTEN_REPORT_DATA":
                return{
                  ...state,
                  flattenedData: action.value?[...state.flattenedData, ...action.value]:[...state.flattenedData],
                  // totalRecordsCount: state.flattenedData.length,
                }
              case "SHOW_REPORT_DROPDOWN":
                return{
                  ...state,
                  reportDropdown: action.value
                }  
              case "CLEAR_REPORT_DATA":
                return{
                  ...state,
                  reportData: [],
                  filteredReportData: [],
                  totalRecordsCount: 0,
                  offsetvalue: "",
                  reportquery: "",
                  reporttransactionDate: "",
                  reporttransactionId: "",
                  flattenedData: [],
                  isSpin: false,
                  isLoad: false,
                  issubmit: false,
                  isReportData: false,
                  reportError: "",
                  isErrorReport: false,
                }
              case "REPORT_ERROR":
                return{
                  ...state,
                  reportError: action.value,
                  isErrorReport: action.iserror,
                  isReportData: false,
                }
              case "UPDATE_TEXT_QUERY":
                return{
                  ...state,
                  testQuerySearchQuery: action.value,
                  testQueryCheck: state.testQuerySearchQuery&&state.testQuerySearchQuery.length>4?(true):(false)
                }
              case "UPDATE_SEARCH_OPTION":
                return{
                  ...state,
                  searchOptions: action.option,
                  searchOptionsObject: action.value,
                }
              case "RESET_SEARCH_OPTION":
                return{
                  ...state,
                  searchOptions: [],
                  searchOptionsObject: [],
                  testQueryCheck: state.testQuerySearchQuery&&state.testQuerySearchQuery.length>4?(true):(false)
                } 
              case "SET_ADDRESS_KEY":
                return{
                  ...state,
                  searchAddressKey: action.value,
                  isFormatApi: action.value?true:false,
                }
              case "SET_FORMAT_ADDRESS_1":
                return{
                  ...state,
                  formatAddress1: action.value
                }
              case "SET_FORMAT_ADDRESS_2":
                return{
                  ...state,
                  formatAddress2: action.value
                }
              case "SET_FORMAT_TOWN_CITY":
                return{
                  ...state,
                  formatTownCity: action.value
                }
              case "SET_FORMAT_COUNTY":
                return{
                  ...state,
                  formatCounty: action.value
                }
              case "SET_FORMAT_POST_CODE":
                return{
                  ...state,
                  formatPostCode: action.value
                }
              case "SET_FORMAT_COUNTRY":
                return{
                  ...state,
                  formatCountry: action.value
                }
              case "IS_FORTMAT_FETCH":
                return{
                  ...state,
                  isFormatFetch: action.value
                }
              case "RESET_FORMAT_ADDRESS":
                return{
                  ...state,
                  isFormatFetch: false,
                  formatAddress1: "",
                  formatAddress2: "",
                  formatTownCity: "",
                  formatCounty: "",
                  formatPostCode: "",
                  formatCountry: "",
                }
            default:
                return;
        }
  }

export const QueryContext = React.createContext(initialState);

export const QueryProvider = (props) => {

  const [queryState, queryDispatch] = useReducer(reducer, initialState);

  return (
    <QueryContext.Provider value={{ queryState, queryDispatch }}>
      {props.children}
    </QueryContext.Provider>
  );
};


function myFunction(value) {

  let fullAddress = value['Address1'] + " " + value['Address2'] + " " + value['City'] + " " + value['CompanyName'] + " " + value['ComplainceId'] + " " + value['CountryCode'] + " " + value['CountryCode'] + " " + value['FormattedAddress'] + " " + value['Language'] + " " + value['PostalCode']+ " " + value['StateCode']+ " " + value['Type']
  
  return fullAddress;
}

const submitQuery = async (filterState, dispatch, body) => {
 
  try {

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const [response, statusCode] = await dal.postRequest(`${process.env.REACT_APP_API_BASE_PATH}v1/compliance/eKyc`, requestOptions)
    
    let result = response;
    
    if(statusCode === 200){
      if(JSON.parse(response).message){
        dispatch({type: "FETCH_ERROR", value: "Something went wrong!!", status:true});
      }else{
        dispatch({type: "FETCH_API_DATA", payload: result});
      }
    }else if(statusCode === 406 || statusCode === 403){
      dispatch({type: "FETCH_ERROR", value: response.errorMessage, status:false});
    }else{
      dispatch({type: "FETCH_ERROR", value: response.errorMessage?(response.errorMessage):(response.message), status:true});
    }

    return result;
  } catch (error) {
    console.log(error)
    dispatch({ type: "FETCH_ERROR", value: "Something went wrong!!!!", status:true });
  }
};

const fetchReportData = async (queryState, queryDispatch) => {

  try{

    queryDispatch({ type: "CLEAR_REPORT_DATA" })
    queryDispatch({ type: "IS_SPIN", spin: true })
    queryDispatch({ type: "IS_SUBMITTING", issubmit: true })
    
    const response = await dal.request(`${process.env.REACT_APP_API_BASE_PATH}v1/compliance/report?fromDate=${queryState.fromDate}&toDate=${queryState.toDate}&query=${queryState.reportquery}&transactionDate=&transactionId=&filterCondition=${queryState.filterparam}`);
    // const response = await dal.request(`https://dev-ui-api.cnr.tvx-test.cloud/ui/v1/compliance/report?fromDate=${queryState.fromDate}&toDate=${queryState.toDate}&query=${queryState.reportquery}&transactionDate=&transactionId=&filterCondition=${queryState.filterparam}`)

    if (response && response.status === 200) {
      queryDispatch({ type: "IS_LOADING", load: true })

        typeof (response.data) === "string" ? (response.data = JSON.parse(response.data)) : (response.data = response.data);
        
        if(typeof response.data.DBRecords !== 'undefined' && response.data.DBRecords.length > 0){
          await queryDispatch({ type: "FETCH_REPORT_DATA_NEW", data: response.data.DBRecords, isdata: true, spin: false, entiredetailflag: queryState.entireDetailsFlag, offset: response.data.offset });
          let flatdata = await response.data.DBRecords.map(crushObj);

          flatdata = flatdata.filter(data => data.source ? true : false)
          await queryDispatch({ type: "FLATTEN_REPORT_DATA", value: flatdata });

          if(response.data.offset){

            fetchReportDataBatch(queryState, queryDispatch, response.data.offset);
          }else{
            queryDispatch({ type: "IS_LOADING", load: false });
          }
        }else{
          throw {
            message: "Data not fetch"
          }
        }
    }

  }catch (error) {
    queryDispatch({ type: "IS_SPIN", spin: false })
    queryDispatch({ type: "IS_LOADING", load: false })
    queryDispatch({ type: "REPORT_ERROR", value: error.message, iserror: true })
    queryDispatch({ type: "IS_SUBMITTING", issubmit: false })
  }

}

const fetchReportDataBatch = async (queryState, queryDispatch, offset) => {
  
  let offsetFlag = true;
  let reportQueryParam = offset.queryParam;
  let transactiondate = offset.transactionDate;
  let transactionID = offset.transactionId;

  while (offsetFlag){
    // const response = await dal.request(`https://dev-ui-api.cnr.tvx-test.cloud/ui/v1/compliance/report?fromDate=${queryState.fromDate}&toDate=${queryState.toDate}&query=${reportQueryParam}&transactionDate=${transactiondate}&transactionId=${transactionID}&filterCondition=${queryState.filterparam}`);
    const response = await dal.request(`${process.env.REACT_APP_API_BASE_PATH}v1/compliance/report?fromDate=${queryState.fromDate}&toDate=${queryState.toDate}&query=${reportQueryParam}&transactionDate=${transactiondate}&transactionId=${transactionID}&filterCondition=${queryState.filterparam}`)
    if(response && response.status === 200 ){

      typeof (response.data) === "string" ? (response.data = JSON.parse(response.data)) : (response.data = response.data);
      
      if(typeof response.data.DBRecords !== 'undefined' && response.data.DBRecords.length > 0){
        if(response.data.offset){

          reportQueryParam = offset.queryParam;
          transactiondate = response.data.offset.transactionDate;
          transactionID = response.data.offset.transactionId;

          await queryDispatch({ type: "FETCH_REPORT_DATA_NEW", data: response.data.DBRecords, isdata: true, spin: false, entiredetailflag: queryState.entireDetailsFlag, offset: response.data.offset });
          let flatdata = await response.data.DBRecords.map(crushObj);

          flatdata = flatdata.filter(data => data.source ? true : false)
          await queryDispatch({ type: "FLATTEN_REPORT_DATA", value: flatdata });

        }else{

          offsetFlag = false;
          
          await queryDispatch({ type: "FETCH_REPORT_DATA_NEW", data: response.data.DBRecords, isdata: true, spin: false, entiredetailflag: queryState.entireDetailsFlag, offset: response.data.offset });
          let flatdata = await response.data.DBRecords.map(crushObj);

          flatdata = flatdata.filter(data => data.source ? true : false)
          await queryDispatch({ type: "FLATTEN_REPORT_DATA", value: flatdata });
          queryDispatch({ type: "IS_LOADING", load: false });
          break;
        }
      }
        
    }else{
      offsetFlag = false;
      break;
    }
  }
}

const fetchkycdoctData = async (queryState, queryDispatch) => {

  try{

    queryDispatch({ type: "CLEAR_REPORT_DATA" })
    queryDispatch({ type: "IS_SPIN", spin: true })
    queryDispatch({ type: "IS_SUBMITTING", issubmit: true })
    
    const response = await dal.request(`${process.env.REACT_APP_API_BASE_PATH}v1/compliance/kycdoc?fromDate=${queryState.fromDate}&toDate=${queryState.toDate}&query=${queryState.reportquery}&transactionDate=&transactionId=&filterCondition=${queryState.filterparam}`);
    console.log('response');
    console.log(response);
    if (response && response.status === 200) {
      queryDispatch({ type: "IS_LOADING", load: true })
      console.log(response.data);
        typeof (response.data) === "string" ? (response.data = JSON.parse(response.data)) : (response.data = response.data);
        
        if(typeof response.data.DBRecords !== 'undefined' && response.data.DBRecords.length > 0){
          await queryDispatch({ type: "FETCH_KYCDOC_DATA_NEW", data: response.data.DBRecords, isdata: true, spin: false, entiredetailflag: queryState.entireDetailsFlag, offset: response.data.offset });
          let flatdata = await response.data.DBRecords.map(crushObj);

          flatdata = flatdata.filter(data => data.source ? true : false)
          await queryDispatch({ type: "FLATTEN_KYCDOC_DATA", value: flatdata });

          if(response.data.offset){
            fetchkycdocDataBatch(queryState, queryDispatch, response.data.offset);
          }else{
            queryDispatch({ type: "IS_LOADING", load: false });
          }
        }else{
          throw {
            message: "Data not fetch"
          }
        }
    }
  }catch (error) {
    queryDispatch({ type: "IS_SPIN", spin: false })
    queryDispatch({ type: "IS_LOADING", load: false })
    queryDispatch({ type: "REPORT_ERROR", value: error.message, iserror: true })
    queryDispatch({ type: "IS_SUBMITTING", issubmit: false })
  }

}

const fetchkycdocDataBatch = async (queryState, queryDispatch, offset) => {
  
  let offsetFlag = true;
  let reportQueryParam = offset.queryParam;
  let transactiondate = offset.transactionDate;
  let transactionID = offset.transactionId;

  while (offsetFlag){
    const response = await dal.request(`${process.env.REACT_APP_API_BASE_PATH}v1/compliance/kycdoc?fromDate=${queryState.fromDate}&toDate=${queryState.toDate}&query=${reportQueryParam}&transactionDate=${transactiondate}&transactionId=${transactionID}&filterCondition=${queryState.filterparam}`)
    if(response && response.status === 200 ){

      typeof (response.data) === "string" ? (response.data = JSON.parse(response.data)) : (response.data = response.data);
      
      if(typeof response.data.DBRecords !== 'undefined' && response.data.DBRecords.length > 0){
        if(response.data.offset){

          reportQueryParam = offset.queryParam;
          transactiondate = response.data.offset.transactionDate;
          transactionID = response.data.offset.transactionId;

          await queryDispatch({ type: "FETCH_REPORT_DATA_NEW", data: response.data.DBRecords, isdata: true, spin: false, entiredetailflag: queryState.entireDetailsFlag, offset: response.data.offset });
          let flatdata = await response.data.DBRecords.map(crushObj);

          flatdata = flatdata.filter(data => data.source ? true : false)
          await queryDispatch({ type: "FLATTEN_REPORT_DATA", value: flatdata });

        }else{

          offsetFlag = false;
          
          await queryDispatch({ type: "FETCH_REPORT_DATA_NEW", data: response.data.DBRecords, isdata: true, spin: false, entiredetailflag: queryState.entireDetailsFlag, offset: response.data.offset });
          let flatdata = await response.data.DBRecords.map(crushObj);

          flatdata = flatdata.filter(data => data.source ? true : false)
          await queryDispatch({ type: "FLATTEN_REPORT_DATA", value: flatdata });
          queryDispatch({ type: "IS_LOADING", load: false });
          break;
        }
      }
        
    }else{
      offsetFlag = false;
      break;
    }
  }
}

const crushObj = (obj = {}) => Object.keys(obj || {}).reduce((acc, cur) => {
  if (cur === 'headers' || cur === 'suffix' || (cur === 'apiResponse' && obj['apiResponse'] === '')) { }
  else if (cur === "body" && typeof obj[cur] === 'string') {
      acc = { ...acc, ...crushObj(JSON.parse(obj[cur])) }
  }
  else if (typeof obj[cur] === 'object') {
      acc = { ...acc, ...crushObj(obj[cur]) }
  } else { acc[cur] = obj[cur] }
  return acc
}, {})